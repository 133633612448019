import Vue from "vue";
import moment from "moment";
Vue.filter("fixedCurrency", function (el) {
  // check if the value is actually a string
  if (typeof el === "string") {
    // remove commas and convert string to number using the unary plus operator
    return +el.replace(/,/g, "");
  }

  return Number(el).toFixed(3);
});

Vue.filter("formatDate", function (date) {
  let locale = "en-US";
  // Create a new Date object from the input
  const parsedDate = new Date(date);

  // Check if the date is valid
  if (isNaN(parsedDate)) return "-";

  const options = { year: "numeric", month: "short", day: "numeric" };
  return new Intl.DateTimeFormat(locale, options).format(parsedDate);
});

Vue.filter("shorten", (value) => {
  return value.slice(0, 15) + "...";
});
